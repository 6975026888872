import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-parent.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Table = makeShortcode("Table");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "a-what-strategies-does-the-school-use-to-communicate-with-me",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-what-strategies-does-the-school-use-to-communicate-with-me",
        "aria-label": "a what strategies does the school use to communicate with me permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A. What Strategies Does The School Use To Communicate With Me?`}</h2>
    <h4 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h4>
    <p>{`PSI promotes a positive partnership between teachers and parents as an important tool in ensuring student academic and behavioural success. Therefore, the School has put into place a number of procedures and events to foster this communication. Parents are encouraged to contact the staff and school leadership on a regular basis in order to be well-informed regarding their child’s progress.`}</p>
    <p>{`The language of instruction is English and all documentation is sent out in this language. Parents should make sure that they understand all correspondence from the School. Most often, we use email as a medium to contact parents, which is why it is extremely important that parents provide us with their current email addresses. Parents who need help understanding the content of any School communication should contact the sender as soon as possible for assistance.`}</p>
    <p>{`PSI has developed a comprehensive system for communicating student progress, news, and upcoming events.`}</p>
    <p>{`In order to keep parents from feeling overloaded, the School sends information home to groups of parents on `}<strong parentName="p">{`Fridays`}</strong>{` only. This includes class and school newsletters, announcements about field trips and other events, and requests for parent help. There are a few exceptions to this rule. Emergency information (e.g., a school closure, time-sensitive information) will go out as needed. Mandatory health forms related to COVID are sent on Mondays. Teachers communicating with one parent regarding a student’s academic or behavioural issues may also contact parents on an as-needed basis.`}</p>
    <h4 {...{
      "id": "publications-and-announcements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#publications-and-announcements",
        "aria-label": "publications and announcements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Publications and Announcements`}</h4>
    <p>{`Below is an overview of strategies for helping parents stay well informed throughout the year.`}</p>
    <Table innerWidth={1000} fullWidth mdxType="Table">
    <table>
        <colgroup>
            <col style={{
            "width": "32%"
          }} />
            <col style={{
            "width": "31%"
          }} />
            <col style={{
            "width": "36%"
          }} />
        </colgroup>
        <thead>
        <tr className="header">
            <th><strong>What You’re Looking For</strong></th>
            <th><strong>Where You Should Look</strong></th>
            <th><strong>How You’ll Find It</strong></th>
        </tr>
        </thead>
        <tbody>
        <tr className="odd">
            <td>An overview of the School’s <strong>‘nuts and bolts’ for operation</strong> as well as major policies and
                procedures
            </td>
            <td><p>Parent and Student Handbook</p>
                <p>(for both Primary and Secondary School)</p></td>
            <td><p>Provided to new parents in digital copy</p>
                <p>Available on the PSI website, MyPSI Portal and PSI App</p></td>
        </tr>
        <tr className="even">
            <td>Updates, reminders, and links to attendance, sports and the cafeteria</td>
            <td><p>PSI App</p>
                <p>PSI Today</p>
                <p>Friday Communications</p></td>
            <td><p>In related sections</p>
                <p><a href="https://www.facebook.com/groups/PSI.Today/"> Closed Facebook Group </a></p>
                <p>MyPSI Portal, PSI App, or email from PSI Communication</p></td>
        </tr>
        <tr className="odd">
            <td>A <strong>weekly update</strong> from the homeroom teacher (grade level teachers will share the weekly,
                news, events, homework, etc. )
            </td>
            <td>Friday Communications</td>
            <td>Available on Fridays via email from teachers</td>
        </tr>
        <tr className="even">
            <td>All School News, announcements and communication from the director, principals, ECA, PTA, Athletics, PHE
                &amp; PSPE
            </td>
            <td>Weekly Digest (Friday afternoons)</td>
            <td><p>Sent on Fridays</p>
                <p>Available via email from PSI Communication, and on the PSI App in the ‘Communication’ section</p></td>
        </tr>
        <tr className="even">
            <td>General <strong>announcements and reminders, cancellation dates</strong> (including community news)</td>
            <td><p>PSI App</p>
                <p>PSI Today</p>
                <p>Reception Bulletin Boards</p></td>
            <td><p>In the reminders section</p>
                <p>Closed Facebook group</p>
                <p>Reception area</p></td>
        </tr>
        <tr className="odd">
            <td>Success of <strong>student learning and activities</strong></td>
            <td>PSI <em>Life Magazine</em></td>
            <td>Posted to <a href="https://publications.psi.kiev.ua/"> Publications Platform </a> of the school’s website,
                <a href="https://www.facebook.com/groups/PSI.Today/"> PSI Today </a>, and PSI App
            </td>
        </tr>
        <tr className="even">
            <td><strong>Curriculum and assessment (PYP or MYP / DP)</strong></td>
            <td><p>Primary Programme Guide</p>
                <p>Secondary Programme Guide</p></td>
            <td>PSI website, PSI App</td>
        </tr>
        <tr className="odd">
            <td><strong>Classroom learning, homework, grades (</strong>24/7 on-line system<strong>)</strong></td>
            <td>MyPSI</td>
            <td><a href="https://portals.veracross.eu/psi"> Parent Portal </a>, website</td>
        </tr>
        <tr className="even">
            <td><strong>Student progress</strong></td>
            <td><p>Primary Reports: January and June</p>
                <p>Secondary Reports: January and June</p>
                <p>Parent Conferences**</p>
                <p>Student Led Conferences / Showcases**</p>
                <p>Three Way Conferences (Primary)</p>
                <p>**</p>
                <p>Parent - Student - Teacher Conferences (Secondary)</p></td>
            <td>MyPSI / Parent portal</td>
        </tr>
        <tr className="odd">
            <td><strong>Emergency information</strong></td>
            <td>SMS, Email</td>
            <td>Text messages sent directly to parents’ phones, email follow up.</td>
        </tr>
        <tr className="even">
            <td><strong>Important information</strong> - contagious diseases, etc.</td>
            <td>Email</td>
            <td>Sent by emails.</td>
        </tr>
        <tr className="odd">
            <td><strong>Opportunities to learn more</strong> about the school and connect with other parents</td>
            <td><p>Parent Teacher Association (PTA) Meetings</p>
                <p>Parent Education Tuesdays (PET)</p>
                <p>Coffee with Director’ and ‘Coffee with the Board’</p>
                <p>Town Hall Meetings</p></td>
            <td><p>- PTA meetings are held monthly on Tuesdays at 8:30 in the Cafeteria or via ZOOM.</p>
                <p>- PET sessions run on all other Tuesdays in the Cafe Meeting Room at 8:30 or via ZOOM.</p>
                <p>‘Coffee with the Director’ and ‘Coffee with the Board’ schedule is available in ‘<a href="https://calendar.google.com/calendar/embed?src=psi.kiev.ua_gbqag3vvv3t0kl2giqsq9gpbrc%40group.calendar.google.com&amp;ctz=Europe%2FKiev">
                    All School Calendar </a>’</p></td>
        </tr>
        </tbody>
    </table>
    </Table>
    <h4 {...{
      "id": "mypsi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#mypsi",
        "aria-label": "mypsi permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MyPSI`}</h4>
    <p>{`All teachers now maintain a web presence on MyPSI, the School’s database and communication system. All parents have access and are encouraged to check these regularly for information about student learning and student progress. School Reports and MAP testing results can be found in your MyPSI portal. Parents experiencing difficulty with access should contact the IT Department (`}<a parentName="p" {...{
        "href": "mailto:it@psi.kiev.ua"
      }}>{` it@psi.kiev.ua `}</a>{`).`}</p>
    <h4 {...{
      "id": "meetings-with-teachers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#meetings-with-teachers",
        "aria-label": "meetings with teachers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Meetings with Teachers`}</h4>
    <p>{`Each fall, PSI offers a Back-to-School or Curriculum Afternoon to help all parents understand more about the daily functioning of the School. In addition, parent-teacher meetings are scheduled twice a year in Secondary, usually following report cards, and three times a year in Primary. Parents make appointments with teachers through My PSI. Appointments for Parent Conferences are usually 15 minutes for Primary (homeroom teachers) and 10 minutes per single subject subject teacher in Primary and single subject teacher in Secondary. While we promote an on-line calendar (Secondary) to help in communication, direct contact is always best when a student is having difficulties. We ask that parents meet with teachers upon request. In addition, we encourage any parent who wishes a meeting to make an appointment with the teacher.`}</p>
    <h4 {...{
      "id": "student-led-conferences-showcases",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#student-led-conferences-showcases",
        "aria-label": "student led conferences showcases permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Student-Led Conferences Showcases`}</h4>
    <p>{`Students in EC - Grade 8 hold annual Student-Led Conferences / Showcases in which they present their work for the year and discuss their learning challenges and accomplishments. Parents are requested to attend these events. They are not opportunities to discuss student progress with the teacher. They are designed as a way for students to communicate directly with their parents about their own annual progress.`}</p>
    <h4 {...{
      "id": "three-way-conferences-primary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#three-way-conferences-primary",
        "aria-label": "three way conferences primary permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Three-Way Conferences (Primary)`}</h4>
    <p>{`Students in EC - Grade 5 hold conferences with their parents and teachers to discuss their goals for the year. These goals are academic and social / emotional and are aligned to the PYP and Learner Profile. These conferences are not used to discuss current progress, but the goals set forth by your child as they move forward throughout the school year.`}</p>
    <h4 {...{
      "id": "parent-student-teacher-conferences-secondary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#parent-student-teacher-conferences-secondary",
        "aria-label": "parent student teacher conferences secondary permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Parent-Student-Teacher Conferences (Secondary)`}</h4>
    <p>{`In both November and April, the Secondary school hosts parent-student-teacher conferences. The parent may sign-up for a meeting with their child’s teachers. Parents, along with their child, will have the opportunity to meet with each teacher for 10 minutes. The purpose of the meetings are to discuss strengths, progress and potential next steps to facilitate deeper learning in their varied classes.`}</p>
    <h4 {...{
      "id": "sharing-our-learning-events-primary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#sharing-our-learning-events-primary",
        "aria-label": "sharing our learning events primary permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sharing Our Learning Events (Primary)`}</h4>
    <p>{`Learning celebrations happen throughout the school year in Primary School and can take place while children are in the middle of learning something (the process of learning) or at the end of a unit of learning (the product of learning). Parents are invited to come to classes to celebrate, along with their children, the learning that is taking place. Some celebrations may include listening to children read their published work, observing children while they explain a science experiment and the procedures they followed, or listening to children work through math problems using manipulatives.`}</p>
    <h2 {...{
      "id": "b-what-are-the-best-ways-for-me-to-communicate-with-the-school",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#b-what-are-the-best-ways-for-me-to-communicate-with-the-school",
        "aria-label": "b what are the best ways for me to communicate with the school permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`B. What Are The Best Ways For Me To Communicate With The School?`}</h2>
    <p>{`We encourage parents to always start as close to the source of the issue as possible. For example, classroom issues should be addressed first with the classroom teacher before moving it to the administrative level. To assist in that process, we have identified some of the key people running various programmes throughout the School.`}</p>
    <h4 {...{
      "id": "whos-who-at-psi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#whos-who-at-psi",
        "aria-label": "whos who at psi permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Who’s Who At PSI?`}</h4>
    <Table innerWidth={1000} fullWidth padding={'md'} frame={'solid-grey'} mdxType="Table">
    <table>
        <colgroup>
            <col style={{
            "width": "29%"
          }} />
            <col style={{
            "width": "38%"
          }} />
            <col style={{
            "width": "32%"
          }} />
        </colgroup>
        <thead>
        <tr className="header">
            <th><strong>If you have a question about:</strong></th>
            <th><strong>Person responsible:</strong></th>
            <th><strong>Method of contact:</strong></th>
        </tr>
        </thead>
        <tbody>
        <tr className="odd">
            <td><p>Classroom issues:</p>
                <ul>
                    <li><p>progress of your child</p></li>
                    <li><p>class routines</p></li>
                    <li><p>schedule</p></li>
                    <li><p>homework</p></li>
                </ul>
            </td>
            <td><p>Class teacher / Homeroom teacher</p>
                <p>NOTE: Individual teacher addresses are listed in this handbook in the section on Staff.</p></td>
            <td>
                <ul>
                    <li><p>Email or call the teacher for an appointment</p></li>
                </ul>
                <p>OR</p>
                <ul>
                    <li><p>Email Primary school office at: primary@psi.kiev.ua </p></li>
                    <li><p>Email Secondary school office at: lenal@psi.kiev.ua </p></li>
                </ul>
            </td>
        </tr>
        <tr className="even">
            <td>Whole school issues, policy and development, safety and security</td>
            <td>Rachel Caldwell, Director</td>
            <td><a href="mailto:rachelc@psi.kiev.ua">rachelc@psi.kiev.ua</a></td>
        </tr>
        <tr className="odd">
            <td>Booking an appointment with the director</td>
            <td>Nikita Vasyliev, Director’s PA:</td>
            <td> directorspa@psi.kiev.ua</td>
        </tr>
        <tr className="even">
            <td>Primary school educational policies, organisation and routines</td>
            <td>Jan Humbleby, Primary School Principal</td>
            <td><a href="mailto:janh@psi.kiev.ua"> janh@psi.kiev.ua </a></td>
        </tr>
        <tr className="odd">
            <td>Secondary school educational policies, organisation and procedures</td>
            <td><p>Jessica Krueger, Secondary School Principal</p>
                <p>La Mor, Secondary Assistant Principal</p></td>
            <td><p><a href="mailto:jessicak@psi.kiev.ua">jessicak@psi.kiev.ua</a></p>
                <p> lam<a href="about:blank">@psi.kiev.ua</a></p></td>
        </tr>
        <tr className="even">
            <td><p>PYP curriculum questions</p>
                <p>MYP curriculum questions</p>
                <p>DP curriculum / exam questions</p></td>
            <td><p>Erika Olson, PYP Coordinator</p>
                <p>La Mor, MYP Coordinator</p>
                <p>David Freeman, Diploma Coordinator</p></td>
            <td><p> erikao@psi.kiev.ua </p>
                <p> lam@psi.kiev.ua </p>
                <p> davidf@psi.kiev.ua </p></td>
        </tr>
        <tr className="odd">
            <td><p>Extra-Curricular Activities (ECAs)</p>
                <p>and Mother Tongue Languages</p></td>
            <td>Tetiana Maslova, ECA Coordinator</td>
            <td> eca@psi.kiev.ua</td>
        </tr>
        <tr className="even">
            <td>Athletic events, CEESA events and teams</td>
            <td>Ric Floyd, Athletic Director</td>
            <td><a href="mailto:athleticdirector@psi.kiev.ua">athleticdirector@psi.kiev.ua</a></td>
        </tr>
        <tr className="odd">
            <td>Guidance and College Counselling</td>
            <td><p>Annie Butkus (EC - Grade 5)</p>
                <p>Michele Basu (Grades 6 - 12)</p></td>
            <td><p><a href="mailto:annieb@psi.kiev.ua"> annieb@psi.kiev.ua </a></p>
                <p><a href="mailto:micheleb@psi.kiev.ua"> micheleb@psi.kiev.ua </a></p></td>
        </tr>
        <tr className="even">
            <td>Health Issues</td>
            <td>School Clinic</td>
            <td><p><a href="mailto:doctor@psi.kiev.ua">doctor@psi.kiev.ua</a></p>
                <p><a href="mailto:leraz@psi.kiev.ua"> leraz@psi.kiev.ua </a></p></td>
        </tr>
        <tr className="odd">
            <td><strong>If you have a question about:</strong></td>
            <td><strong>Person responsible:</strong></td>
            <td><strong>Method of contact:</strong></td>
        </tr>
        <tr className="even">
            <td>Accounting and Billing</td>
            <td>Inna Kokoreva , Cashier</td>
            <td><a href="mailto:cashier@psi.kiev.ua"> cashier@psi.kiev.ua </a></td>
        </tr>
        <tr className="odd">
            <td><p>Changes in parent contact information (emails, phone numbers, etc)</p>
                <p>Withdrawal from school</p></td>
            <td>Tetiana Dronchak , Admissions Manager</td>
            <td> admissions@psi.kiev.ua</td>
        </tr>
        <tr className="even">
            <td>IT support</td>
            <td>IT Department</td>
            <td><a href="mailto:it@psi.kiev.ua"> it@psi.kiev.ua </a></td>
        </tr>
        <tr className="odd">
            <td>Booking of cafe meeting room, cafeteria</td>
            <td>Natalia Kryzhanivska<br />
                Receptionist
            </td>
            <td> secretary@psi.kiev.ua</td>
        </tr>
        <tr className="even">
            <td>Booking of auditorium, 4th floor gallery, 4th floor meeting rooms</td>
            <td>IT Department</td>
            <td> it@psi.kiev.ua</td>
        </tr>
        <tr className="odd">
            <td>Bus service / routes</td>
            <td>Lilia Gotvyanska, Bus Coordinator</td>
            <td><a href="mailto:bus@psi.kiev.ua">bus@psi.kiev.ua</a></td>
        </tr>
        <tr className="even">
            <td>Security Cards</td>
            <td>Andriy Sorokin, Head of Security</td>
            <td><a href="mailto:security@psi.kiev.ua">security@psi.kiev.ua</a></td>
        </tr>
        <tr className="odd">
            <td>Absences</td>
            <td>Natalia Kryzhanivska, Attendance Secretary</td>
            <td> absence@psi.kiev.ua</td>
        </tr>
        </tbody>
    </table>
    </Table>
    <p><strong parentName="p">{`Written Communication with School`}</strong></p>
    <p>{`PSI recognises the need for clear communication channels between parents and the school. We particularly support the opportunity for parents and teachers to communicate directly via email. Teachers’ email addresses are emailed to families separately at the beginning of the school year and are available in the Staff section of this handbook (page 43).`}</p>
    <p>{`Please note the following parental guidelines in the use of any email communication with the school:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Emails can be used to inform the teachers of something that happened at home or to ask questions that require only a brief answer.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If there are issues that require a discussion or a longer explanation, it is better to arrange an appointment with the teacher.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The nature of some teacher schedules may result in some emails only being answered the next day, but we try to answer all correspondence within 24 hours.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Any urgent issues that need to be addressed on the same day need to be communicated via the secretaries.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Any communication between home and school should always be cordial and respectful.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`As we want our teachers to maintain a well-balanced lifestyle, emails received during a weekend or break will be answered upon return to campus.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Procedure for Parental Concerns`}</strong></p>
    <p>{`PSI recognises the need for the home and the school to address concerns effectively and provides them with a procedure for constructive discussion. The steps below are to be followed strictly.`}</p>
    <p><strong parentName="p">{`Step One:`}</strong>{` The parents should communicate with the subject or homeroom teacher.`}</p>
    <p><strong parentName="p">{`Step Two:`}</strong>{` The parents have the right to confer with the relevant subject or programme coordinator if, after seeing the teacher, they require further discussion.`}</p>
    <p><strong parentName="p">{`Step Three:`}</strong>{` If, after seeing the relevant coordinator, the matter is not resolved and the parents require further discussion, an appointment may be set with the principal. The parents may reasonably expect a response within five (5) working days.`}</p>
    <p><strong parentName="p">{`Step Four:`}</strong>{` If the parents feel the issue is not addressed, they may bring the matter to the director, whose decision is final. Once again, an appointment must be made through the director’s personal assistant (PA). The director will respond within five (5) working days.`}</p>
    <p>{`Please note: While PSI is authorised to run all three IB programmes, the IB does not monitor teaching strategies and programme implementation choices. Questions about the IB programmes at PSI should be addressed to the coordinator of your child’s programme. Please see the `}<a parentName="p" {...{
        "href": "https://ibo.org/contentassets/4217cb074d5f4a77947207a4a0993c8f/rules-for-ib-world-schools-pyp-en-2018.pdf"
      }}>{` Rules for IB World Schools `}</a>{` for more information.`}</p>
    <p><strong parentName="p">{`Parent Conduct Guidelines`}</strong></p>
    <p>{`PSI is an orderly and safe school, where relationships between staff and visitors, especially parents, must demonstrate mutual respect and a recognition of shared responsibility for students’ welfare and educational progress. Parental involvement is an important factor in educational success and in dealing with emerging problems at an early stage.`}</p>
    <p>{`The PSI governing body is responsible for protecting the health and safety of school staff and students. As such, they have developed `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1eows0nLS2x3k0jEs0x8od7VVhKYFXJLgUj1fRmIM4GU/edit"
      }}>{` a   Parent Code   of Conduct `}</a>{` that all parents must sign and adhere to. The Code lays out the School’s expectations for parents and outlines the steps taken if problems arise.`}</p>
    <p>{`##C. HOW DOES THE SCHOOL WORK WITH OUTSIDE TUTORS?**`}</p>
    <p>{`PSI teachers and administrators are happy to work with students, parents and tutors on supporting students as they work at home. These guidelines are meant to outline the best ways for all of us to do that.`}</p>
    <p><em parentName="p">{`Parents should:`}</em></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Help the tutor understand that the student must turn in his / her own work. Tutors and parents may not do work for students! At Primary, the student may be asked to re-do the work if it appears the tutor or parent has done the work. At Secondary, the student will be asked to complete the work on their own after a meeting with the teacher and relevant coordinator.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Give the names, contact information, and subjects of any tutors to the classroom or subject area teacher.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Send the school written permission to release information about the student to the tutor; PSI cannot release any student information without a signed release from parents.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide tutors with their login information for MyPSI if needed. PSI cannot release that information to third parties.`}</p>
      </li>
    </ul>
    <p><em parentName="p">{`Tutors should:`}</em></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Keep in contact with the classroom teacher for clarifications, access issues, student needs and progress updates`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Access materials via the student’s MyPSI and / or through the classroom teacher`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Work with the students by supporting them in their efforts, but not by doing the work for them`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Support and assist students with formative assignments, including some extra practice if needed`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensure that summative assessments are the student's own independent work with no outside influence or assistance`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Recognise that assignments, rubrics, worksheets and resources are the intellectual property of PSI and may not be used for other purposes`}</p>
      </li>
    </ul>
    <p><em parentName="p">{`Students should:`}</em></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Help tutors with access to MyPSI`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Keep track of their assignments and due dates`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ask the teacher questions to make sure that all assignments are understood`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensure that all work completed is their own and not the work of a tutor, parent, other student, or outside source`}</p>
      </li>
    </ul>
    <p><em parentName="p">{`PSI will:`}</em></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Communicate with parents about student progress`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Communicate with tutors about students only with the written permission of parents`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Work with tutors to help them understand the IB programmes, requirements of assignments, and needs of the student`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "d-how-does-psi-help-me-communicate-with-my-child-s-next-school-or-university",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#d-how-does-psi-help-me-communicate-with-my-child-s-next-school-or-university",
        "aria-label": "d how does psi help me communicate with my child s next school or university permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`D. How Does PSI Help Me Communicate With My Child’ S Next School Or University?`}</h2>
    <p>{`PSI assists students and their families who are applying to other schools or universities. Typically, this involves requests for report cards, transcripts, predicted grades in DP, application forms, and letters of reference. Please be aware that we work under the following guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Parents should provide the admissions office with pertinent data prior to withdrawing their child. We encourage parents to begin the process as early as possible to ensure that we are able to help them meet any deadlines set by the receiving school. PSI needs 7 - 10 working days to process requests. Students applying to universities are encouraged to work within the timelines set by the college counsellor. Requests made during school holidays may take longer to process.`}</li>
    </ul>
    {
      /* */
    }
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The School provides time, space and a supervisor for exams from the receiving school. Parents are invoiced for this service.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Parents must be in good standing with the school’s Finance Office. This means that all tuition fees, CEESA costs and other bills must be paid in full. Upon departure, all final fees - including lost library books, textbooks and / or resources, must be paid before a final release of records.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Students receive a checkout form that must be signed by teachers, the librarian, the athletics department, the cafeteria company, the IT office, the Finance Office, and the principal to indicate that all materials have been turned in and all work is up-to-date.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`PSI sends transcripts, predicted grades, application forms and letters of reference only to the receiving school, university or organisation (e.g., UCAS). We do not release them directly to students, parents or agents of parents. This helps the receiving school ensure that the information is accurate and ensures that letters of reference are confidential. PSI releases prior report cards and letters confirming attendance dates to parents upon request.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Parents who hire outside agents to assist with school or university searches must contact the principal and / or counsellor to provide the name and contact information of the agent. Again, PSI does not release paperwork directly to the agent, but we are happy to work with those individuals on preparing appropriate documentation for the receiving schools.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      